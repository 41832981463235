<template>
  <div>
    <el-card>
      <h2 class="Tips">注意：蓝色按钮表示已报名参评的项目，灰色按钮表示未申请的项目！</h2>
      <el-form size="small" :model="form" label-width="120px" id="test">
        <el-row class="title">
          <h2>{{ year }}年{{ quarter }}{{ title }}</h2>
          <img src="../../../assets/EQA_logo.png" />
          <div class="line"></div>
        </el-row>
        <el-row :gutter="24">
          <el-col :span="12">
            <el-form-item label="单位名称">
              <el-input type="text" v-model="form.unitName" disabled></el-input>
            </el-form-item>

            <el-form-item label="实验室名称">
              <el-input
                type="text"
                v-model="form.laboratoryName"
                disabled
              ></el-input>
            </el-form-item>
          </el-col>

          <el-col :span="12">
            <el-form-item label="实验室编号">
              <el-input
                type="text"
                v-model="form.laboratoryCode"
                disabled
              ></el-input>
            </el-form-item>

            <el-form-item label="回报截止日期">
              <el-input
                type="text"
                v-model="form.returnDate"
                disabled
              ></el-input>
            </el-form-item>
          </el-col>
        </el-row>

        <el-form-item
          :label="item.prodName"
          class="protitle"
          v-for="(item, index) in projectList"
          :key="index"
          label-width="380px"
        >
          <el-table
            :data="item.productInfoList"
            border
            size="mini"
            style="width: 100%"
          >
          <el-table-column>
              <template slot-scope="scope">
                <el-switch
                  v-model="scope.row.status"
                  active-value="1"
                  inactive-value="0"
                  disabled
                >
                </el-switch>
              </template>
            </el-table-column>
            <el-table-column
              :label="item.productInfoList[0].reagentBrandTitle"
              width="160"
            >
              <template slot-scope="scope">
                <el-autocomplete
                  popper-class="my-autocomplete"
                  v-model="scope.row.reagentBrand1"
                  :fetch-suggestions="
                    (queryString, cb) => {
                      QueryReagentBrand(
                        queryString,
                        cb,
                        scope.row.reagentBrand
                      );
                    }
                  "
                  placeholder="如没有合适选项，请手动输入"
                  :disabled="scope.row.status == '0'"
                >
                </el-autocomplete>
              </template>
              <!-- <el-input type="text" v-model="form.tableData.name"></el-input> -->
            </el-table-column>
            <el-table-column
              :label="item.productInfoList[0].checkMethodTitle"
              width="200"
            >
              <template slot-scope="scope">
                <el-autocomplete
                  popper-class="my-autocomplete"
                  v-model="scope.row.checkMethod1"
                  style="width: 230px"
                  :fetch-suggestions="
                    (queryString, cb) => {
                      QueryCheckMethod(queryString, cb, scope.row.checkMethod);
                    }
                  "
                  placeholder="如没有合适选项，请手动输入"
                  :disabled="scope.row.status == '0'"
                >
                </el-autocomplete>
                <!-- <el-radio-group v-model="scope.row.checkMethod1">
                  <el-radio
                    v-for="(item, index) in scope.row.checkMethod"
                    :label="item"
                    :key="index"
                    >{{ item }}</el-radio
                  >
                </el-radio-group> -->
              </template>
            </el-table-column>
            <el-table-column
              :label="item.productInfoList[0].operationMethodTitle"
              width="160"
            >
              <template slot-scope="scope">
               
                <el-autocomplete
                v-if="scope.row.operationMethod.length !=0"
                  popper-class="my-autocomplete"
                  v-model="scope.row.operationMethod1"
                  :fetch-suggestions="
                    (queryString, cb) => {
                      QueryOperationMethod(
                        queryString,
                        cb,
                        scope.row.operationMethod
                      );
                    }
                  "
                  placeholder="如没有合适选项，请手动输入"
                  :disabled="scope.row.status == '0'"
                >
                </el-autocomplete>
                 <el-input
                  v-else
                  type="text"
                  v-model="scope.row.operationMethod1"
                  :disabled="scope.row.status == '0'"
                  oninput="value=value.replace(/[^0-9.]/g,'')"
                  placeholder="只能输入数字和小数点"
                ></el-input>
              </template>
            </el-table-column>
            <el-table-column
              :label="item.productInfoList[0].modelTitle"
              width="170"
            >
              <template slot-scope="scope">
                <el-input
                v-if="scope.row.operationMethod.length !=0"
                  type="text"
                  v-model="scope.row.model"
                  :disabled="scope.row.status == '0'"
                ></el-input>
                <el-input
                  v-else
                  type="text"
                  v-model="scope.row.model"
                  :disabled="scope.row.status == '0'"
                  oninput="value=value.replace(/[^0-9.]/g,'')"
                  placeholder="只能输入数字和小数点"
                ></el-input>
              </template>
            </el-table-column>
            <el-table-column
              :label="item.productInfoList[0].checkResult1Title"
              width="170"
            >
              <template slot-scope="scope">
                <el-input
                  type="text"
                  v-model="scope.row.checkResult1"
                  :disabled="scope.row.status == '0'"
                  oninput="value=value.replace(/[^0-9.]/g,'')"
                  placeholder="只能输入数字和小数点"
                ></el-input>
              </template>
            </el-table-column>
            <el-table-column
              :label="item.productInfoList[0].checkResult2Title"
              width="170"
            >
              <template slot-scope="scope">
                <el-input
                  type="text"
                  v-model="scope.row.checkResult2"
                  :disabled="scope.row.status == '0'"
                  oninput="value=value.replace(/[^0-9.]/g,'')"
                  placeholder="只能输入数字和小数点"
                ></el-input>
              </template>
            </el-table-column>
          </el-table>
        </el-form-item>
        <el-row class="tips">
          <p>
            （数据填报举例：若MAR检查结果为53.2%，则直接填报“53.2”，不要填报“53.2%”或“0.532”）
          </p>
        </el-row>
        <el-row :gutter="24" class="bottom_row">
          <el-col :span="12">
            <el-form-item label="实验室负责人">
              <el-input
                type="text"
                v-model="form.qualityControlPerson"
                disabled
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="负责人手机">
              <el-input type="text" v-model="mobile" disabled></el-input>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <div class="btn">
        <el-button type="primary" @click="preserve">保存</el-button>
        <el-button type="primary" @click="sub">提交</el-button>
      </div>
    </el-card>
  </div>
</template>
<script>
export default {
  data() {
    return {
      username: "",
      year: "",
      quarter: "",
      title: "",
      mobile: "",
      flag: false,
      // 上面部分表单
      form: {
        companyname: "",
        laboratoryname: "",
        laboratoryid: "",
        data: "",
        person: "",
        phone: "",
      },
      isOther: false,
      // 检查项目列表
      projectList: [],
      a: [{ value: "酶法" }, { value: "吲哚法" }, { value: "其他" }],
      tableContent: false,
    };
  },
  created() {
    this.year = this.$route.query.year;
    this.quarter = this.$route.query.quarter;
    this.title = this.$route.query.title;
    this.form.phone = window.sessionStorage.getItem("phone");
    this.mobile = window.sessionStorage.getItem("phone");
    this.getResportDetails();

    console.log(this.form.phone);
  },
  methods: {
    checkInputs(val, p) {
      console.log(p);
      return val.replace(/[^0-9.]/g, "");
    },
    // 获取上报表内容
    async getResportDetails() {
      const { data: res } = await this.$http.post("UserEvaluate/selectReport", {
        year: this.year,
        quarter: this.quarter,
        mobile: this.mobile,
      });
      console.log(res);
      if (res.code != 200) {
        this.$message.error(res.message);
      } else {
        this.projectList = res.data.applyReportList;
        this.form = res.data;
      }
      // console.log(this.projectList[0].productInfoList[0].checkMethod)
    },
    // 保存数据
    async preserve() {
      const { data: res } = await this.$http.post("UserEvaluate/commitReport", {
        applyActivityStatus: "0",
        applyReportList: this.projectList,
        year: this.year,
        quarter: this.quarter,
        mobile: this.mobile,
        returnDate: this.form.returnDate,
      });
      if (res.code != 200) {
        this.$message.error(res.message);
      } else {
        this.$message.success("保存数据成功");
        this.getResportDetails();
      }
    },

    async sub() {
      const a = this.projectList.map((item) => {
        return item.productInfoList[0];
      });
      const b = a.filter((item) => {
        return item.status == "1";
      });
      for (let i = 0; i < b.length; i++) {
        if (
          !b[i].reagentBrand1 ||
          !b[i].checkMethod1 ||
          !b[i].operationMethod1 ||
          !b[i].model ||
          !b[i].checkResult1 ||
          !b[i].checkResult2
        ) {
          this.$message.warning("请填写完所有数据再提交");
          return;
        }
      }
      console.log(b);
      const { data: res } = await this.$http.post("UserEvaluate/commitReport", {
        applyActivityStatus: "1",
        applyReportList: this.projectList,
        year: this.year,
        quarter: this.quarter,
        mobile: this.mobile,
        returnDate: this.form.returnDate,
      });
      if (res.code != 200) {
        this.$message.error(res.message);
      } else {
        this.$message.success("数据提交成功");
      }
    },
    // 试剂品牌
    QueryReagentBrand(queryString, cb, reagentBrand) {
      var restaurants = reagentBrand.map((item) => {
        return { value: item };
      });

      var results = queryString
        ? restaurants.filter(this.createFilter(queryString))
        : restaurants;
      cb(results);
    },
    // 检测方法
    QueryCheckMethod(queryString, cb, checkMethod) {
      var restaurants = checkMethod.map((item) => {
        return { value: item };
      });

      var results = queryString
        ? restaurants.filter(this.createFilter(queryString))
        : restaurants;
      cb(results);
    },
    // 操作方法
    QueryOperationMethod(queryString, cb, operationMethod) {
      var restaurants = operationMethod.map((item) => {
        return { value: item };
      });

      var results = queryString
        ? restaurants.filter(this.createFilter(queryString))
        : restaurants;
      cb(results);
    },
    createFilter(queryString) {
      return (restaurant) => {
        return (
          restaurant.value.toLowerCase().indexOf(queryString.toLowerCase()) ===
          0
        );
      };
    },
  },
};
</script>
<style lang='less'>
.Tips{
  margin-left: 30px;
  margin-bottom: 10px;
  // font-size: 18px;
}
#test {
  border: 1px solid #000;
  border-collapse: collapse;
  width: 96%;
  margin: 0 auto;
  .tips {
    text-align: center;
  }
  .title {
    text-align: center;
    margin: 20px 0;
    height: 70px;
    img {
      width: 165px;
      height: 50px;
      z-index: 99;
      background: #fff;
    }
    .line {
      width: 100%;
      border-bottom: 3px dashed #ccc;
      margin-top: -34px;
      z-index: -1;
    }
  }
  .protitle .el-form-item__label {
    line-height: 115px;
    font-size: 16px;
    font-weight: bold;
  }
  .bottom_row .el-form-item {
    margin-bottom: 0;
  }
  .el-form-item {
    border: 1px solid #000;
    border-collapse: collapse;
    .el-table {
      color: #000;
    }
    .el-input {
      border-left: 1px solid #000;
    }
    .el-input__inner {
      width: 99%;
      border: none;
    }
  }

  .el-table td.el-table__cell,
  .el-table th.el-table__cell.is-leaf {
    border-bottom: 1px solid #000;
    color: #000;
  }
  .el-table--border .el-table__cell,
  .el-table__body-wrapper
    .el-table--border.is-scrolling-left
    ~ .el-table__fixed {
    border-right: 1px solid #000;
  }
  .el-table--border,
  .el-table--group {
    border-left: 1px solid #000;
  }
  .el-table--enable-row-transition .el-table__body td.el-table__cell {
    padding: 0;
    margin: 0;
  }
  .el-table .cell,
  .el-table--border .el-table__cell:first-child .cell {
    padding: 0;
    margin: 0;
    font-size: 16px;
    text-align: center;
  }
  .el-form-item__label {
    text-align: center;
  }
  th {
    font-weight: 500;
  }
}
/deep/.el-autocomplete-suggestion {
  width: 230px !important;
}
.btn {
  margin: 20px 40px 0 0;
  float: right;
}
</style>
