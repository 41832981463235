import Vue from 'vue'
import VueRouter from 'vue-router'

// 用户登录
import UserLogin from '../components/UserLog/UserLogin.vue'  //用户登录
import Register from '../components/UserLog/Register.vue'  //用户登录
import SignUp from '../components/UserLog/SignUp.vue'   //报名表
import NoticeList from '../components/UserLog/NoticeList.vue'  //公告列表
import NoticeDetails from '../components/UserLog/NoticeDetails.vue'  //公告列表详情
import NewsList from '../components/UserLog/NewsList.vue'     //新闻列表
import UserNewsDetails from '../components/UserLog/UserNewsDetails.vue'  //新闻列表详情
import UserHome from '../components/UserLog/UserHome.vue'     //用户后台首页

// 用户活动公告
import UserNocite from '../components/UserLog/usernocite/UserNocite.vue'
import UserNociteDetails from '../components/UserLog/usernocite/UserNociteDetails.vue'  //公告详情
// 用户室间测评
import UserReport from '../components/UserLog/usereva/UserReport.vue' //上报列表
import UserReportdetails from '../components/UserLog/usereva/UserReportdetails.vue' //上报列表详情
import UserResult from '../components/UserLog/usereva/UserResult.vue'  //成绩列表
import UsResList from '../components/UserLog/usereva/UsResList.vue'  //成绩列表

//个人信息管理
import UserInfo from '../components/UserLog/userinfo/UserInfo.vue'

// 帮助中心
import UserExplain from '../components/UserLog/userhelp/UserExplain.vue'  //操作说明
import UserContact from '../components/UserLog/userhelp/UserContact.vue'  //联系管理员


Vue.use(VueRouter)



const router = new VueRouter({
  routes: [
    { path: '/', redirect: '/userlogin' }, 
    { path: '/userlogin', component: UserLogin },
    { path: '/register', component: Register },
    { path: '/signup', component: SignUp },
    { path: '/noticeslist', component: NoticeList },
    { path: '/noticedetails', component: NoticeDetails },
    { path: '/newslist', component: NewsList },
    { path: '/usernewsdetails', component: UserNewsDetails },
    {
      path: '/userhome', component: UserHome, redirect: '/usernocite',meta: { requireAuth: true}, children: [
        { path: '/usernocite', component: UserNocite,meta: { requireAuth: true} },
        { path: '/usernocitedetails',name: '/usernocitedetails', component: UserNociteDetails,meta: { requireAuth: true} },
        { path: '/userreport', component: UserReport,meta: { requireAuth: true} },
        { path: '/userreportdetails', name: '/userreportdetails', component: UserReportdetails,meta: { requireAuth: true} },
        { path: '/userresult', component: UserResult,meta: { requireAuth: true} },
        { path: '/usreslist', component: UsResList,meta: { requireAuth: true} },
        { path: '/userinfo', component: UserInfo,meta: { requireAuth: true} },
        { path: '/userexplain', component: UserExplain,meta: { requireAuth: true} },
        { path: '/usercontact', component: UserContact,meta: { requireAuth: true} },
      ]
    },
  ]
})

router.beforeEach((to, from, next) => {
  // to 将要访问的路径
  // from 代表从哪个路径跳转而来
  // next 是一个函数，表示放行
  //     next()  放行    next('/login')  强制跳转
  if(to.meta.requireAuth == true){
    const tokenStr = window.localStorage.getItem('token')
    if (!tokenStr) return next('/userlogin')
    next()
  }else{
    next();
  }

  // if (to.path === '/userlogin') return next()
  // // || '/noticeslist' || '/usernoticedetails' if (to.path === '/newslist') return next()
  // // if (to.path === '/usernewsdetails') return next()
  // // if (to.path === '/register') return next()
  // // if (to.path === '/signup') return next()
  // // 获取token
  // const tokenStr = window.sessionStorage.getItem('token')
  // if (!tokenStr) return next('/userlogin')
  // next()
})

export default router
