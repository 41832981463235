<template>
  <div>
    <el-card>
      <div class="carditem">
        <p class="title">{{ title }}</p>
        <div v-html="content"></div>
      </div>

      <div class="select">
        <el-checkbox-group v-model="checkedPro">
          <el-checkbox
            v-for="item in projectList"
            :label="item.id"
            :key="item.index"
            :disabled="join"
            >{{ item.prodName }}</el-checkbox
          >
        </el-checkbox-group>
        <el-button type="primary" @click="JoinPro" :disabled="join"
          >确认提交</el-button
        >
      </div>
    </el-card>
  </div>
</template>

<script>
let Base64 = require("js-base64").Base64;
export default {
  data() {
    return {
      phone: "",
      username: "",
      title: "",
      content: "",
      year: "",
      quarter: "",
      projectList: [
        // { title: "精浆果糖定量" },
        // { title: "精浆果糖定量1" },
        // { title: "精浆果糖定量2" },
        // { title: "精浆果糖定量3" },
        // { title: "精浆果糖定量4" },
      ],
      checkedPro: [],
      join: "",
    };
  },
  created() {
    this.id = this.$route.query.id;
    this.year = this.$route.query.year;
    this.quarter = this.$route.query.quarter;
    this.phone = window.sessionStorage.getItem("phone");
    this.username = window.sessionStorage.getItem("userName");
    this.getNociteDetails();
    this.getProList();
    this.IsJoin();
  },
  methods: {
    // 获取公告详情
    async getNociteDetails() {
      const { data: res } = await this.$http.post(
        "sys/news/info/newsInfoById",
        {
          id: this.id,
        }
      );
      if (res.code != 200) {
        this.$message.errror(res.message);
      } else {
        this.title = res.data.title;
        this.content = Base64.decode(res.data.content);
      }
    },
    // 验证是否已经参加
    async IsJoin() {
      const { data: res } = await this.$http.post("activity/apply/isTake", {
        year: this.year,
        quarter: this.quarter,
        mobile: this.phone,
      });
      if (res.code != 200) {
        this.$message.errror(res.message);
      } else {
        this.join = res.data;
      }
    },
    // 获取项目列表
    async getProList() {
      const { data: res } = await this.$http.post(
        "quality/evaluate/select/check",
        {
          year: this.year,
          quarter: this.quarter,
        }
      );
      console.log(res);
      if (res.code != 200) {
        this.$message.error(res.message);
      } else {
        this.projectList = res.data;
      }
    },
    // 参加项目
    async JoinPro() {
      if (this.checkedPro.length < 2) {
        this.$message.error("至少选择两个项目参加");
      } else {
        const { data: res } = await this.$http.post("activity/apply", {
          userName: this.username,
          productList: this.checkedPro,
          mobile: this.phone,
          year: this.year,
          quarter: this.quarter,
        });
        if (res.code !== 200) {
          this.$message.error(res.message);
        } else {
          this.$message.success("参加项目成功");
          this.$router.push("/userreport");
        }
      }
    },
  },
};
</script>
<style lang='less' scoped>
.carditem {
  margin: 0 !important;
  width: 100% !important;
  .title {
    text-align: center;
  }
  .content {
    text-indent: 2em;
    white-space: pre-wrap;
  }
}
.select {
  //background: red;
  width: 100%;
  margin: 70px auto 0;
  // display: flex;
  // justify-content: space-around;
  // flex-wrap: nowrap
  .el-checkbox {
    width: 25%;
    // width: 160px;
    margin: 5px 0;
    // text-align: center;
  }
  /deep/.el-checkbox__label {
    // width: 400px;
    font-size: 18px;
  }
  /deep/.el-checkbox__inner {
    width: 18px;
    height: 18px;
    border: 2px solid #000;
  }
  .el-button {
    display: block;
    margin: 50px auto 0;
  }
}
</style>