<template>
  <div>
    <el-card>
      <div class="notice_inner" v-for="item in contactList" :key="item.index">
        <div class="title" @click="showDetails(item.id)">
          <i class="el-icon-caret-right"></i>
          <span>{{ item.title }}</span>
        </div>
      </div>

      <div class="content">
      <h3>{{title}}</h3>
      <div v-html="content"></div>
    </div>
    </el-card>
  </div>
</template>

<script>
let Base64 = require("js-base64").Base64;
export default {
  data() {
    return {
      contactList: [],
      title: "",
      content: "",
      currPage: 1,
      totalSize: 10,
    };
  },
  created(){
    this.getContactList()
  },
  methods:{
     // 获取用户说明列表
    async getContactList() {
      const { data: res } = await this.$http.post("sys/news/info/list", {
        currPage: this.currPage,
        titleStatus: 3,
        totalSize: this.totalSize,
      });
      if (res.code != 200) {
        this.$message.error(res.message);
      } else {
        this.contactList = res.data.records
      }
      console.log(res);
    },
    // 查看详情
     async showDetails(id) {
      const { data: res } = await this.$http.post(
        "sys/news/info/newsInfoById",
        {
          id: id,
        }
      );
       if(res.code !=200){
          this.$message.errror(res.message)
      }else{
          this.title = res.data.title;
        this.content = Base64.decode(res.data.content);
      }
      console.log(res);
    },
  }
};
</script>
<style lang='less' scoped>
.notice_inner {
    padding: 0 100px;
  display: flex;
  justify-content: space-between;
  line-height: 25px;
  // font-size: 14px;
  // margin-bottom: 20px;
  .title{
    cursor: pointer;
  }
  i {
    display: inline-block;
    margin-right: 5px;
    color: #ccc;
    line-height: 27px;
  }
}
.content{
  width: 60%;
  margin: 50px auto 0;
      h3{
          font-size: 25px;
          text-align: center;
      }
      p{
          // text-indent: 2em;
          font-size: 18px;
          line-height: 30px;
          white-space: pre-wrap;
      }
  }
</style>