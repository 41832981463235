<template>
  <div>
    <div class="head">
      <el-carousel :interval="5000" arrow="always">
        <el-carousel-item>
          <img src="../../assets/01.png" />
        </el-carousel-item>
        <el-carousel-item>
          <img src="../../assets/02.png" />
        </el-carousel-item>
      </el-carousel>
    </div>
    <div class="main">
      <div class="left">
        <div class="notice">
          <div class="title" @click="goNotice">
            <span>通知公告</span>
            <span>more</span>
          </div>
          <div class="notice_inner">
            <div
              class="inner"
              v-for="item in noticeList"
              :key="item.index"
              @click="goNoticedetails(item.id)"
            >
              <div>
                <i class="el-icon-caret-right"></i>
                {{ item.title }}
              </div>
              <p>{{item.createDate.slice(0,10)}}</p>
            </div>
          </div>
        </div>

        <div class="news">
          <div class="title" @click="gonews">
            <span>新闻与资料</span>
            <span>more</span>
          </div>
          <div class="news_inner">
            <div
              class="inner"
              v-for="item in newsList"
              :key="item.index"
              @click="goNewsdetails(item.id)"
            >
              <div class="name">
                <i class="el-icon-caret-right"></i>
                {{ item.title }}
              </div>
              <p>{{item.createDate.slice(0,10)}}</p>
            </div>
          </div>
        </div>
      </div>

      <div class="right">
        <div class="log">
          <el-form
            :model="UserForm"
            ref="UserLoginRef"
            :rules="UserLoginRules"
            label-width="100px"
            class="demo-ruleForm"
          >
            <el-form-item label="用户名" prop="username">
              <el-input
                v-model="UserForm.username"
                placeholder="请输入手机号码或用户名"
              ></el-input>
            </el-form-item>
            <el-form-item label="密码" prop="password" show-password>
              <el-input
                type="password"
                v-model="UserForm.password"
                show-password
                @keyup.enter.native="login"
                placeholder="请输入密码"
              ></el-input>
            </el-form-item>
            <el-form-item>
              <el-button @click="login">登录</el-button>
              <el-button @click="registe">注册</el-button>
            </el-form-item>
          </el-form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      currPage: 1,
      totalSize: 10,
      // 公告列表
      noticeList: [],
      // 新闻列表
      newsList: [],
      // 登录表单
      UserForm: {
        username: "",
        password: "",
        phone: "",
        code: "",
      },
      UserLoginRules: {
        username: [
          { required: true, message: "请输入手机号或用户名", trigger: "blur" },
        ],
        password: [
          { required: true, message: "请输入密码", trigger: "blur" },
          {
            min: 6,
            max: 15,
            message: "密码长度在6-15位数字或字母",
            trigger: "blur",
          },
        ],
      },
    };
  },
  created() {
    this.getNoticeList();
    this.getNewsList();
    window.localStorage.clear()
  },
  methods: {
    // 登录
    login() {
      this.$refs.UserLoginRef.validate(async (val) => {
        if (!val) {
        } else {
          const { data: res } = await this.$http.post("person/login", {
            mobile: this.UserForm.phone,
            password: this.UserForm.password,
            username: this.UserForm.username,
          });
          console.log(res);
          if (res.code !== 200) {
            this.$message.error(res.message);
          } else {
            this.$message.success("登陆成功");
            this.UserForm.phone = res.data.mobile;
            window.localStorage.setItem("token", res.data.accessToken);
            this.$router.push({
              path: "/userhome",
            });
          }
        }
      });
    },
    // 获取公告列表
    async getNoticeList() {
      const { data: res } = await this.$http.post("sys/evaluate/select", {
        currPage: this.currPage,
        titleStatus: 1,
        totalSize: this.totalSize,
      });
      if (res.code != 200) {
        this.$message.error(res.message);
      } else {
        this.noticeList = res.data.records;
      }
    },
    // 获取新闻列表
    async getNewsList() {
      const { data: res } = await this.$http.post("sys/evaluate/select", {
        currPage: this.currPage,
        titleStatus: 0,
        totalSize: this.totalSize,
      });
      if (res.code != 200) {
        this.$message.error(res.message);
      } else {
        this.newsList = res.data.records;
      }
    },
    // 公告列表详情
    goNoticedetails(id) {
      this.$router.push({
        path: "/usernewsdetails",
        query: {
          id: id,
        },
      });
    },
    // 新闻列表详情
    goNewsdetails(id) {
      this.$router.push({
        path: "/noticedetails",
        query: {
          id: id,
        },
      });
    },
    registe() {
      this.$router.push("/register");
    },
    goNotice() {
      this.$router.push("/noticeslist");
    },
    gonews() {
      this.$router.push("/newslist");
    },
  },
};
</script>
<style lang='less' scoped>
.main {
  // width: 65%;
  width: 1235px;
  margin: 0 auto;
  position: relative;
  .left {
    // width: 35%;
    width: 490px;
    // background: #ccc;
    position: absolute;
    top: 30px;
    left: 30px;
    .notice,
    .news {
      width: 100%;
      height: 270px;
      // background: yellow;
      .title {
        padding: 0 23px;
        // margin-bottom: 5px;
        display: flex;
        justify-content: space-between;
        cursor: pointer;
        span:nth-of-type(1) {
          font-weight: 700;
          font-size: 20px;
        }
      }
    }
    .notice_inner,
    .news_inner {
      margin: 0 auto;
      // width: 300px;
      width: 490px;
      height: 200px;
      border: 1px solid #d3d4d4;
      padding:  10px;
      box-sizing: border-box;
      overflow: hidden;
      background: #e4e7ed;
      .inner {
        margin-top: 5px;
        cursor: pointer;
        display: flex;
        justify-content: space-between;
        .name{
          width: 340px;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
      }
    }
  }
  .right {
    // width: 342px;
    // height: 310px;
    width: 460px;
    height: 465px;
    position: absolute;
    // top: 105px;
    top: 60px;
    // right: 85px;
    right: 38px;
    text-align: center;
    // transform: scale(1.2);
    border: 1px solid #d3d4d4;
    background: #e4e7ed;
    .log {
      // height: 100%;
      // padding: 100px 40px 35px 0;
      // box-sizing: border-box;
      // margin-bottom: 30px;
      .el-form {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        left: 40px;
      }
      .el-input-group__append {
        padding: 0;
      }
      .codebtn {
        width: 65px;
        padding: 0;
        font-size: 12px;
      }
    }
  }
}
// .el-input-group--prepend .el-input__inner, .el-input-group__append{
//   padding: 0;
// }
</style>