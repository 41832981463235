import Vue from 'vue'
import App from './App.vue'
import router from './router'
import './plugins/element.js'
import './assets/css/global.css'
import './assets/css/index.less'
import axios from 'axios'


// 是否显示重新登录
let isReloginShow;

// axios.defaults.baseURL = 'http://192.168.3.10:7001/api/'
// axios.defaults.baseURL = 'http://119.23.74.131:7001/api/' 
axios.defaults.baseURL = 'http://androlab-eqa.com/api/' 
// axios.defaults.baseURL = 'http://sit1.androlab-eqa.com/api/'  
Vue.prototype.$http1 = axios.defaults.baseURL

/* 请求拦截 */
axios.interceptors.request.use(config => {
  // console.log(config)
  config.headers.accessToken = window.localStorage.getItem('token')
  // config.headers. Access-Control-Allow-Credentials = '*' 
  // 在最后必须 return config
  return config
})

/* 请求响应 */
axios.interceptors.response.use(
  response => {
    // console.log(response.data.code)
    const code = response.data.code;
    const message = response.data.message;
    if(code === 403 || code === 401){
      if(!isReloginShow){
        isReloginShow = true;
        Vue.prototype.$confirm('登录已失效，请重新登录', '系统提示', {
          confirmButtonText: '重新登录',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(()=>{
          window.localStorage.clear()
          window.sessionStorage.clear()
          router.push('/userlogin')
        }).catch(() => {
          isReloginShow = false;
        });
      }
      return Promise.reject('无效的会话，或者会话已过期，请重新登录。')
    }else if(code == 5006){
      
    }
    else if(code !=200 && code!='000000'){
      Vue.prototype.$message.error(message)
    }
    else{
      return response
    }
  },
  err => {
    // console.log('errrrr'+err)
    let { message } = err
    if(message.includes("403") || message.includes("401")){
      if(!isReloginShow){
        isReloginShow = true;
        Vue.prototype.$confirm('登录已失效，请重新登录', '系统提示', {
          confirmButtonText: '重新登录',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(()=>{
          window.localStorage.clear()
          window.sessionStorage.clear()
          router.push('/userlogin')
        }).catch(() => {
          isReloginShow = false;
        });
      }
    }
  }
)
Vue.prototype.$http = axios

Vue.config.productionTip = false

new Vue({
  router,
  render: h => h(App)
}).$mount('#app')
