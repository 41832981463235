<template>
  <div>
    <el-card>
      <div class="notice_inner" v-for="item in explainList" :key="item.index">
        <div class="title" @click="godetails(item.id)">
          <i class="el-icon-caret-right"></i>
          <span>{{ item.title }}</span>
        </div>

        <span class="data">{{ item.createDate }}</span>
      </div>
    </el-card>
  </div>
</template>

<script>
export default {
  data() {
    return {
      explainList: [],
      currPage: 1,
      totalSize: 10,
    };
  },
  created(){
    this.getExplainList()
  },
  methods:{
    // 获取用户说明列表
    async getExplainList() {
      const { data: res } = await this.$http.post("sys/news/info/list", {
        currPage: this.currPage,
        titleStatus: 2,
        totalSize: this.totalSize,
      });
      if (res.code != 200) {
        this.$message.error(res.message);
      } else {
        this.explainList = res.data.records;
      }
      console.log(res);
    },
    // 查看详情
      godetails(id) {
      this.$router.push({
        path: "/noticedetails",
        query: {
          id: id,
        },
      });
    },
  }
};
</script>
<style lang='less' scoped>
.notice_inner {
    padding: 0 100px;
  display: flex;
  justify-content: space-between;
  line-height: 25px;
  // font-size: 14px;
  // margin-bottom: 20px;
  .title{
    cursor: pointer;
  }
  i {
    display: inline-block;
    margin-right: 5px;
    color: #ccc;
    line-height: 27px;
  }
}
</style>