<template>
  <div>
    <el-card>
      <div class="carditem">
        <div class="title" v-for="item in resultList" :key="item.index">
          <i class="el-icon-caret-right"></i>
          <p @click="godetails(item.year, item.quarter, item.title)">
            {{ item.year + "年" + item.quarter + item.title }}
          </p>
        </div>
      </div>
      <!-- <el-button type="primary">创建上报表</el-button> -->
    </el-card>
  </div>
</template>

<script>
export default {
  data() {
    return {
      currPage: 1,
      totalSize: 10,
      resultList: [],
    };
  },
  created() {
    this.getResultlist();
  },
  methods: {
    async getResultlist() {
      const { data: res } = await this.$http.post("quality/evaluate/list", {
        currPage: this.currPage,
        totalSize: this.totalSize,
        // title: "质评成绩表",
        titleCode:'3'
      });
      if (res.code !== 200) {
        this.$message.error(res.message);
      } else {
        this.resultList = res.data.records;
      }
    },
    godetails(year, quarter, title) {
      this.$router.push({
        path: "/usreslist",
        query: {
          year: year,
          quarter: quarter,
          title: title,
        },
      });
    },
  },
};
</script>

<style lang="less" scoped>
</style>