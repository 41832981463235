<template>
  <div>
    <el-card>
      <el-form :model="userForm">
        <el-form-item label="用户名:" label-width="125px">
          <el-input type="text" v-model="userForm.username" disabled></el-input>
        </el-form-item>
        <el-form-item label="密码:" label-width="125px">
          <el-input type="password" v-model="userForm.password" show-password></el-input>
        </el-form-item>
        <el-form-item label="单位名称:" label-width="125px">
          <el-input type="text" v-model="userForm.unitName"></el-input>
        </el-form-item>
        <el-form-item label="实验室名称:" label-width="125px">
          <el-input type="text" v-model="userForm.laboratoryName"></el-input>
        </el-form-item>
        <el-form-item label="质控联系人:" label-width="125px">
          <el-input type="text" v-model="userForm.qualityControlPerson"></el-input>
        </el-form-item>
        <el-form-item label="联系人电话:" label-width="125px">
          <el-input type="text" v-model="userForm.mobile"></el-input>
        </el-form-item>
        <el-form-item label="联系人邮箱:" label-width="125px">
          <el-input type="text" v-model="userForm.qualityContPerMail"></el-input>
        </el-form-item>
        <el-form-item label="质控品投递地址:" label-width="125px">
          <el-input type="text" v-model="userForm.qualityContPerAddress"></el-input>
        </el-form-item>
      </el-form>
      <el-button type="primary" @click="updata">修改</el-button>
      
    </el-card>
  </div>
</template>

<script>
export default {
  data() {
    return {
      username:'',
      mobile:'',
      userForm:{
      }
    };
  },
  created(){
    this.username = window.sessionStorage.getItem('userName')
    this.mobile = window.sessionStorage.getItem('phone')
    this.getUserInfo()
  },
  methods:{
    async getUserInfo(){
      const {data:res} = await this.$http.post('person/getUserInfo',{
        username:this.username,
        mobile:this.mobile
      })
      if(res.code !=200){
        this.$message.error(res.message)
      }else{
        this.userForm=res.data
      }
    },
   async updata(){
      const confirm = await this.$confirm("确定修改您的个人信息吗?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      }).catch(err => err);
       if (confirm == "confirm") {
        const { data: res } = await this.$http.post(
          "person/update",this.userForm
        );
        if (res.code != 200) {
          this.$message.error(res.message);
        } else {
          this.$message.success("修改信息成功");
          this.getUserInfo();
        }
      }
    }
  }
};
</script>
<style lang='less' scoped>
.el-form{
  width: 600px;
  margin: 60px auto 30px;
}
.el-button{
  position: relative;
  left: 50%;
  width: 150px;
  margin-left: -75px;
}
</style>