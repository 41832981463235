<template>
  <div>
    <div class="head">
      <el-carousel :interval="5000" arrow="always">
        <el-carousel-item>
          <img src="../../assets/01.png" />
        </el-carousel-item>
        <el-carousel-item>
          <img src="../../assets/02.png" />
        </el-carousel-item>
      </el-carousel>
    </div>
    <el-form
      :model="RegisterForm"
      ref="RegisterRef"
      :rules="RegisterRules"
      label-width="100px"
    >
      <el-form-item label="手机号码" prop="phone">
        <el-input type="text" v-model="RegisterForm.phone"></el-input>
      </el-form-item>
      <el-form-item label="" prop="code">
        <el-input
          type="text"
          v-model="RegisterForm.code"
          placeholder="输入验证码"
        >
          <el-button
            class="codebtn"
            slot="append"
            @click="getCode"
            v-show="codeShow"
            >获取验证码</el-button
          >
          <el-button class="codebtn" slot="append" v-show="!codeShow"
            >{{ count }}S重新获取</el-button
          >
        </el-input>
      </el-form-item>
      <el-form-item>
        <el-button
          type="primary"
          class="res"
          @click="gosignup(RegisterForm.phone)"
          >下一步</el-button
        >
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
export default {
  data() {
    //验证手机号码验证
    var checkMobile = (rule, value, cb) => {
      const regMobile = /^1[34578]\d{9}$/;
      if (regMobile.test(value)) {
        return cb();
      }
      //返回一个错误提示
      cb(new Error("请输入正确的手机号"));
    };
    return {
      RegisterForm: {
        phone: "",
        code: "",
      },
      RegisterRules: {
        phone: [
          { required: true, message: "请输入手机号码", trigger: "blur" },
          { validator: checkMobile, trigger: "blur" },
        ],
        code: [{ required: true, message: "请输入验证码", trigger: "blur" }],
      },
      // 验证码倒计时
      codeShow: true,
      count: "",
      timer: null,
    };
  },
  methods: {
    async getCode() {
      const { data: res } = await this.$http.post("code/sms/getSMSCode", {
        mobile: this.RegisterForm.phone,
      });
      if (res.code != 200) {
        this.$message.error(res.message);
      } else {
        this.$message.success("验证码已发送到您手机");
        const TIME_COUNT = 60;
        if (!this.timer) {
          this.count = TIME_COUNT;
          this.codeShow = false;
          this.timer = setInterval(() => {
            if (this.count > 0 && this.count <= TIME_COUNT) {
              this.count--;
            } else {
              this.codeShow = true;
              clearInterval(this.timer);
              this.timer = null;
            }
          }, 1000);
        }
      }
    },
    gosignup(phone) {
      this.$refs.RegisterRef.validate(async (val) => {
        // console.log(val);
        if (!val) {
        } else {
          const { data: res } = await this.$http.post(
            "person/validateSMSCode",
            {
              mobile: this.RegisterForm.phone,
              code: this.RegisterForm.code,
            }
          );
          console.log(res);
          if (res.code !== 200) {
            this.$message.error(res.message);
          } else {
            // window.sessionStorage.setItem("phone", phone);
            this.$router.push({
              path: "/signup",
              query: {
                phone: phone,
              },
            });
          }
        }
      });
    },
    // gosignup(phone) {
    //   console.log(phone);
    //   this.$router.push({
    //     path: "/signup",
    //     query: {
    //       phone: phone,
    //     },
    //   });
    // },
  },
};
</script>
<style lang='less' scoped>
.el-form {
  width: 350px;
  height: 300px;
  margin: 200px auto 0;
  .res {
    width: 150px;
  }
}
</style>