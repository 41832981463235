<template>
  <div>
    <el-card ref="card">
      <div class="title" v-if="active">
        <h2>{{ year + "年" + quarter + title }}</h2>
        <!-- <h2>{{ CentreList[0].paramKey }}&nbsp;</h2> -->
        <h2>{{ paramKey }}&nbsp;</h2>
        <img src="../../../assets/EQA_logo.png" />
        <div class="line"></div>
      </div>
      <el-row :gutter="24" v-if="active">
        <el-col :span="8">
          <p>实验室编号：{{ userForm.laboratoryCode }}</p>
        </el-col>
        <el-col :span="8">
          <p>单位名称：{{ userForm.unitName }}</p>
          <p>实验室名称：{{ userForm.laboratoryName }}</p>
        </el-col>
        <el-col :span="8" class="control">
          <div>质控中心：</div>
          <div class="control_inner">
            <p v-for="(item, index) in CentreList" :key="index">
              {{ item.paramValue }}
            </p>
            <!-- <p>深圳市临床室间质量控制中心</p>
            <p>深圳市临床室间质量控制中心</p> -->
          </div>
          <div v-if="sealList.length == 1" class="seal1">
            <div
              class="seal_inner"
              v-for="(item, index) in sealList"
              :key="index"
            >
              <!-- <img src="../../../assets/01.png" /> -->
              <!-- <img v-if="item.remark" :src="item.remark" crossorigin="*" /> -->
              <img :src="item.remark" />
            </div>
          </div>
          <div v-else-if="sealList.length == 2" class="seal2">
            <div
              class="seal_inner"
              v-for="(item, index) in sealList"
              :key="index"
            >
              <!-- <img src="../../../assets/01.png" /> -->
              <!-- <img v-if="item.remark" :src="item.remark" crossorigin="*" /> -->
              <img :src="item.remark" />
            </div>
          </div>
          <div v-else class="seal3">
            <div
              class="seal_inner"
              v-for="(item, index) in sealList"
              :key="index"
            >
              <!-- <img src="../../../assets/01.png" /> -->
              <!-- <img v-if="item.remark" :src="item.remark" crossorigin="*" /> -->
              <img :src="item.remark" />
            </div>
          </div>
        </el-col>
      </el-row>
      <el-table :data="resultList" border id="retable" v-if="active">
        <el-table-column
          prop="prodNameResult"
          label="检查项目"
          width="420"
        ></el-table-column>
        <el-table-column
          prop="measurementUnit"
          label="计量单位"
        ></el-table-column>
        <el-table-column
          prop="controlMaterialCode"
          label="质控品编号"
        ></el-table-column>
        <el-table-column
          prop="laboratoryNum"
          label="参评实验室"
        ></el-table-column>
        <el-table-column
          prop="laboratoryResult"
          label="你室结果"
        ></el-table-column>
        <el-table-column prop="targetValue" label="靶值"></el-table-column>
        <el-table-column prop="deviation" label="偏倚%"></el-table-column>
        <el-table-column label="PT允许范围" width="140">
          <template slot-scope="scope">
            <span>{{ scope.row.ptValue }}</span>
            <div
              class="blackSpot1"
              v-if="
                scope.row.laboratoryResult != '####' &&
                scope.row.valuePercent < 0
              "
            ></div>
          </template>
        </el-table-column>
        <el-table-column prop="" width="200">
          <template slot="header">
            <span style="float: left; margin-left: -26px">下限</span>
            <span>靶值</span>
            <span style="float: right; margin-right: -22px">上限</span>
          </template>
          <template slot-scope="scope">
            <div class="line"></div>
            <el-slider
              v-model="scope.row.valuePercent"
              disabled
              :show-tooltip="false"
              v-if="
                scope.row.laboratoryResult != '####' &&
                scope.row.valuePercent < 100 &&
                scope.row.valuePercent > 0
              "
            >
            </el-slider>
            <div class="per0" v-else-if="scope.row.valuePercent === 0"></div>
            <div
              class="per100"
              v-else-if="scope.row.valuePercent === 100"
            ></div>
          </template>
        </el-table-column>
        <el-table-column label="PT得分" width="150">
          <template slot-scope="scope">
            <span>{{ scope.row.ptScore }}</span>
            <div
              class="blackSpot2"
              v-if="
                scope.row.laboratoryResult != '####' &&
                scope.row.valuePercent > 100
              "
            ></div>
          </template>
        </el-table-column>
      </el-table>
      <P class="pt" v-if="active">综合PT(%)：{{ ptall }}</P>
    </el-card>
    <el-button type="primary" v-if="active" @click="exportPDF">导出PDF</el-button>
    <el-button type="primary" v-if="active" @click="Print">打印预览</el-button>
  </div>
</template>

<script>
import html2canvas from "html2canvas";
import JsPDF from "jspdf";
import print from "print-js";
export default {
  data() {
    return {
      active: false,
      username: "",
      ptall: "",
      year: "",
      quarter: "",
      mobile: "",
      title: "",
      resultList: [],
      userForm: {},
      CentreList: [],
      sealList: [],
      paramKey: "",
    };
  },
  created() {
    this.year = this.$route.query.year;
    this.quarter = this.$route.query.quarter;
    this.title = this.$route.query.title;
    this.mobile = window.sessionStorage.getItem("phone");
    this.username = window.sessionStorage.getItem("userName");
    this.getUserResult();
    this.getUserInfo();
    this.getUserCentre();
  },
  methods: {
    // 导出PDF模块
    exportPDF() {
      html2canvas(document.body).then((canvas) => {
        document.body.appendChild(canvas);
      });
      //生成海报
      html2canvas(document.querySelector(".el-card"), {
        scale: 2.0,
        useCORS: true,
      }).then((canvas) => {
        let imgUrl = canvas.toDataURL("image/jpeg", 1.0);
        var title = "成绩表";
        const pdf = new JsPDF("l", "mm", [canvas.width, canvas.height]);
        //  pdf.addImage(imgUrl, 'JPEG', 0, 0, 595.28, 841.89);
        pdf.addImage(imgUrl, "JPEG", 0, 0, canvas.width, canvas.height);
        pdf.save(title + ".pdf");
      });
    },
    Print() {
      this.printerLoading = true;
      html2canvas(document.body).then((canvas) => {
        document.body.appendChild(canvas);
      });
      //生成海报
      html2canvas(document.querySelector(".el-card__body"), {
        scale: 2.0,
        useCORS: true,
      }).then((canvas) => {
        let imgUrl = canvas.toDataURL("image/jpeg", 1.0);
        // var title ='成绩表'
        printJS({
          printable: imgUrl,
          type: "image",
          documentTitle: "打印图片",
          // onLoadingStart: this.onLoadingStart(),
          // onLoadingEnd:this.onLoadingEnd()
        });
      });
    },
    // 获取质评中心内容
    async getUserCentre() {
      const { data: res } = await this.$http.post(
        "sysConfig/selectByFirstKey",
        {
          quarter: this.quarter,
          year: this.year,
          firstKey: "qe_centre",
        }
      );
      if (res.code != 200) {
        this.$message.error(res.message);
      } else {
        this.CentreList = res.data;
        this.paramKey = res.data[0].paramKey;
        this.sealList = res.data.filter((item) => {
          return item.remark;
        });
        // console.log(this.CentreList,this.sealList)
      }
    },
    // 获取客户内容
    async getUserInfo() {
      const { data: res } = await this.$http.post("person/getUserInfo", {
        username: this.username,
        mobile: this.mobile,
      });
      if (res.code != 200) {
        this.$message.error(res.message);
      } else {
        this.userForm = res.data;
      }
    },
    // 获取客户成绩
    async getUserResult() {
      const { data: res } = await this.$http.post(
        "result/data/user/result/create",
        {
          year: this.year,
          quarter: this.quarter,
          mobile: this.mobile,
        }
      );
      if (res.code != 200) {
        this.$message.error(res.message);
      } else {
        this.active = true;
        this.resultList = res.data;
        this.ptall = res.data[0].ptAll;
      }
      // console.log(res);
    },
  },
};
</script>
<style lang='less' scoped>
@media print {
  /deep/.el-aside,
  .el-main .top {
    display: none;
  }
  // .el-main .top {
  //   margin-top: 20px !important;
  //  }
}
/deep/ .el-table td.el-table__cell div .el-slider__runway {
  background-color: rgba(0, 0, 0, 0);
  .el-slider__bar {
    background-color: rgba(0, 0, 0, 0);
  }
  .el-slider__button {
    width: 10px;
    height: 10px;
    background-color: #000;
    border: none;
  }
}
/deep/ .el-table {
  .line {
    margin-top: -30px;
    width: 1px;
    height: 100px;
    background: #000;
    position: absolute;
    left: 50%;
    // transform: translateX(-50%);
  }
  .blackSpot1,
  .blackSpot2,
  .per0,
  .per100 {
    width: 10px;
    height: 10px;
    background: #000;
    border-radius: 50%;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
  }
  .blackSpot1 {
    right: 10px;
  }
  .blackSpot2 {
    left: 10px;
  }
  .per0 {
    left: -5px;
  }
  .per100 {
    right: -5px;
  }
}
/deep/.el-table th.el-table__cell {
  overflow: visible;
  background-color: rgba(0, 0, 0, 0);
  .cell {
    overflow: visible;
  }
}
.el-card {
  min-height: 650px;
}
.title {
  text-align: center;
  border: 1px solid #000;
  border-bottom: none;
  height: 120px;
  img {
    width: 165px;
    height: 50px;
    z-index: 99;
    background: #fff;
  }
  .line {
    width: 100%;
    border-bottom: 3px dashed #ccc;
    margin-top: -34px;
    z-index: -1;
  }
  h2 {
    font-size: 18px;
    font-weight: 500;
  }
}
.control {
  position: relative;
  height: 100px;
  .control_inner {
    position: absolute;
    top: 0;
    left: 90px;
    z-index: 100;
  }
  .seal1,
  .seal2,
  .seal3 {
    width: 210px;
    text-align: center;
    position: absolute;
    // z-index: -999;
    // top: -15px;
    left: 90px;
    overflow: hidden;
    .seal_inner {
      display: inline-block;
    }
    .seal_inner {
      margin-left: 10px;
    }
    .seal_inner:nth-of-type(1) {
      margin-left: 0;
    }
    img {
      width: 95px;
      height: 95px;
    }
  }
  .seal1 {
    top: -35px;
  }
  .seal2 {
    top: -25px;
  }
  .seal3 {
    width: auto;
    top: -15px;
  }
}

.pt {
  font-size: 18px;
  text-align: right;
  margin: 20px 110px 0 0;
}
/deep/.el-table th.el-table__cell > .cell {
  text-align: center;
}
/deep/.el-table th.el-table__cell:nth-of-type(8) > .cell {
  text-align: left;
}
/deep/.el-row {
  border: 1px solid #000;
  border-bottom: none;
  margin: 0 !important;
  .el-col {
    margin-top: 20px;
  }
}
.el-table--border,
.el-table--group {
  border: 1px solid #000;
}
/deep/.el-table thead {
  color: #000;
}
/deep/.el-table th.el-table__cell.is-leaf {
  border-bottom: 1px solid #000;
}
/deep/.el-table td.el-table__cell,
.el-table th.el-table__cell.is-leaf {
  // border-bottom: 1px solid #000;
  border-bottom: none;
}

/deep/ .el-table--border .el-table__header-wrapper .el-table__cell {
  // border-right: 1px solid #000;
  border-right: none;
}
/deep/ .el-table--border .el-table__body-wrapper .el-table__cell {
  // border-right: 1px solid #000;
  border-right: none;
  text-align: center;
}
/deep/.el-table--border .el-table__body-wrapper .el-table__cell:nth-of-type(1) {
  text-align: left;
}
/deep/.el-table--border .el-table__body-wrapper .el-table__cell:nth-of-type(8) {
  border-right: 1px solid #000;
  text-align: left;
}
/deep/.el-table--border .el-table__body-wrapper .el-table__cell:nth-of-type(9) {
  border-right: 1px solid #000;
}
.el-button {
  margin: 30px 0 0 30px;
}
</style>