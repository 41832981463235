<template>
  <div id="w">
    <div class="head">
      <el-carousel :interval="5000" arrow="always">
        <el-carousel-item>
         <img src="../../assets/01.png" />
        </el-carousel-item>
        <el-carousel-item>
         <img src="../../assets/02.png" />
        </el-carousel-item>
      </el-carousel>   
    </div>

    <el-container>
      <el-aside width="280px">
        <h2>行业动态</h2>
      </el-aside>
      <el-container>
        <el-header>
          <img src="../../assets/index.png" alt="" />
          <span>首页 >> 行业动态</span>
        </el-header>
        <el-main>
          <div class="notice_inner" v-for="item in newsList" :key="item.index" @click="godetails(item.id)">
            <div class="title">
              <i class="el-icon-caret-right"></i>
              <span>{{ item.title }}</span>
            </div>
            <span class="data">{{ item.createDate.slice(0,10) }}</span>
            <!-- <span class="data">2021-10-25</span> -->
          </div>
          <div class="page">
            <el-pagination
              @size-change="handleSizeChange"
              @current-change="handleCurrentChange"
              :current-page="currPage"
              :page-sizes="[5, 8, 10, 15]"
              :page-size="totalSize"
              layout="total, sizes, prev, pager, next, jumper"
              :total="total"
            >
            </el-pagination>
          </div>
        </el-main>
      </el-container>
    </el-container>
  </div>
</template>

<script>
export default {
  data() {
    return {
      total: 0,
      currPage: 1,
      totalSize: 10,
      // 新闻列表
      newsList: [],
    };
  },
  created() {
    this.getNewslist();
  },
  methods: {
    async getNewslist() {
      const { data: res } = await this.$http.post("sys/news/info/list", {
        currPage: this.currPage,
        titleStatus: 0,
        totalSize: this.totalSize,
      });
      if (res.code != 200) {
        this.$message.error(res.message);
      } else {
        this.newsList = res.data.records;
        this.total = res.data.total;
      }
    },
    handleSizeChange(newSize){
      this.totalSize = newSize
      this.getNewslist()
    },
    handleCurrentChange(newPage){
      this.currPage= newPage
      this.getNewslist()
    },
    godetails(id){
      this.$router.push({
        path:'/noticedetails',
        query:{
          id:id
        }
      })      
    },
  },
};
</script>
<style lang='less' scoped>
#w {
  width: 1350px;
  margin: 0 auto;
  .head {
    width: 100%;
  }
}
.el-container {
  width: 90%;
  margin: 0 auto;
  // border: 2px solid #D9EBF9;
  .el-aside {
    border-top: 6px solid #d9ebf9;
    border-bottom: 12px solid #d9ebf9;
    border-left: 4px solid #d9ebf9;
    border-right: 6px solid #d9ebf9;
    height: 600px;
    h2 {
      text-align: center;
      font-size: 15px;
      border-bottom: 1px solid rgb(243, 243, 243);
      padding: 10px 0;
    }
  }
  .el-header {
    border-top: 6px solid #d9ebf9;
    border-bottom: 1px solid #ddd;
    box-shadow: 0 5px 2px #fbf6e3;
    line-height: 58px;
    img {
      width: 13px;
      height: 35px;
      vertical-align: middle;
    }
    span {
      font-weight: 700;
    }
  }
  .el-main {
    border-bottom: 4px solid #d9ebf9;
    padding: 40px 20px 0;
    .notice_inner {
      display: flex;
      justify-content: space-between;
      line-height: 25px;
      font-size: 14px;
      cursor: pointer;
      // margin-bottom: 20px;
      i {
        display: inline-block;
        margin-right: 5px;
        color: #ccc;
        line-height: 27px;
      }
    }
    .page {
      margin: 40px auto 0;
    }
  }
}
</style>