<template>
  <div>
    <el-card>
      <div class="carditem">
        <div class="title" v-for="item in noticeList" :key="item.index">
          <i class="el-icon-caret-right"></i>
          <p @click="godetails(item.id, item.year, item.quarter)">
            {{ item.title }}
          </p>
        </div>
      </div>
    </el-card>
  </div>
</template>

<script>
export default {
  data() {
    return {
      year: "",
      quarter: "",
      currPage: 1,
      // titleStatus: "1",
      totalSize: 10,
      noticeList: [],
    };
  },
  created() {
    this.getNoticeList();
  },
  methods: {
    async getNoticeList() {
      const { data: res } = await this.$http.post("sys/evaluate/select", {
        currPage: this.currPage,
        titleStatus: 1,
        totalSize: this.totalSize,
      });
      if (res.code != 200) {
        this.$message.error(res.message);
      } else {
        this.noticeList = res.data.records;
      }
    },
    godetails(id, year, quarter) {
      // this.year = e.substr(0,4)
      // this.quarter = e.substr(4,2)
      // console.log(this.year,this.quarter)
      this.$router.push({
        path: "/usernocitedetails",
        // name: "/usernocitedetails",
        query: {
          id: id,
          year: year,
          quarter: quarter,
        },
      });
    },
  },
};
</script>
<style lang='less' scoped>
</style>