<template>
  <div id="registe">
    <div class="head">
      <el-carousel :interval="5000" arrow="always">
        <el-carousel-item>
          <img src="../../assets/01.png" />
        </el-carousel-item>
        <el-carousel-item>
          <img src="../../assets/02.png" />
        </el-carousel-item>
      </el-carousel>
    </div>
    <el-form :model="userForm" :rules="signRules" ref="signRef">
      <el-form-item label="用户名:" label-width="125px" prop="username">
        <el-input
          type="text"
          v-model="userForm.username"
          placeholder="用户名可用作登录账号使用，填写后无法更改"
        ></el-input>
      </el-form-item>
      <el-form-item label="密码:" label-width="125px" prop="password1">
        <el-input
          type="password"
          v-model="userForm.password1"
          show-password
        ></el-input>
      </el-form-item>
      <el-form-item label="确认密码:" label-width="125px" prop="password2">
        <el-input
          type="password"
          v-model="userForm.password2"
          show-password
        ></el-input>
      </el-form-item>
      <el-form-item label="单位名称:" label-width="125px" prop="unitName">
        <el-input type="text" v-model="userForm.unitName"></el-input>
      </el-form-item>
      <el-form-item
        label="实验室名称:"
        label-width="125px"
        prop="laboratoryName"
      >
        <el-input type="text" v-model="userForm.laboratoryName"></el-input>
      </el-form-item>
      <el-form-item
        label="质控联系人:"
        label-width="125px"
        prop="qualityControlPerson"
      >
        <el-input
          type="text"
          v-model="userForm.qualityControlPerson"
        ></el-input>
      </el-form-item>

      <el-form-item
        label="联系人邮箱:"
        label-width="125px"
        prop="qualityContPerMail"
      >
        <el-input type="text" v-model="userForm.qualityContPerMail"></el-input>
      </el-form-item>
      <el-form-item
        label="质控品投递地址:"
        label-width="125px"
        prop="qualityContPerAddress"
      >
        <el-input
          type="text"
          v-model="userForm.qualityContPerAddress"
        ></el-input>
      </el-form-item>
    </el-form>

    <el-dialog
      title="请选择要参加的项目"
      :visible.sync="ProVisible"
      width="30%"
    >
      <el-checkbox-group v-model="checkedPro">
        <el-checkbox
          v-for="item in projectList"
          :label="item.id"
          :key="item.index"
          >{{ item.prodName }}</el-checkbox
        >
      </el-checkbox-group>
      <span slot="footer" class="dialog-footer">
        <el-button @click="ProVisible = false">取 消</el-button>
        <el-button type="primary" @click="subPro">确 定</el-button>
      </span>
    </el-dialog>
    <div class="select">
      <el-button type="primary" @click="sub">确认注册</el-button>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    //验证手机号码验证
    var checkMobile = (rule, value, cb) => {
      const regMobile = /^1[34578]\d{9}$/;
      if (regMobile.test(value)) {
        return cb();
      }
      //返回一个错误提示
      cb(new Error("请输入正确的手机号"));
    };
    return {
      id: 0,
      year: "",
      code: "",
      userForm: {
        laboratoryCode: "", //实验室编号
        laboratoryName: "", //实验室名称
        mobile: "", //手机号 ,
        password1: "", //密码
        password2: "", //确认密码
        qualityContPerAddress: "", //质量控制产品投递地址
        qualityContPerMail: "", //质量控制联系人邮箱
        qualityContPerTel: "", //质量控制联系人电话
        qualityControlPerson: "", //质量控制联系人
        unitName: "", // 单位名称
        username: "", //用户名
      },
      // 对话框显示与隐藏
      ProVisible: false,
      // 表单验证规则
      signRules: {
        username: [
          { required: true, message: "请输入登录名", trigger: "blur" },
        ],
        password1: [
          { required: true, message: "请输入密码", trigger: "blur" },
          {
            min: 6,
            max: 15,
            message: "密码长度在 6 到 15 个字符",
            trigger: "blur",
          },
        ],
        password2: [
          { required: true, message: "请确认密码", trigger: "blur" },
          {
            min: 6,
            max: 15,
            message: "密码长度在 6 到 15 个字符",
            trigger: "blur",
          },
        ],
        unitName: [
          { required: true, message: "请输入单位名称", trigger: "blur" },
        ],
        laboratoryName: [
          { required: true, message: "请输入实验室名称", trigger: "blur" },
        ],
        qualityControlPerson: [
          { required: true, message: "请输入质控联系人", trigger: "blur" },
        ],
        // qualityContPerTel: [
        //   { required: true, message: "请输入手机号码", trigger: "blur" },
        //   { validator: checkMobile, trigger: "blur" },
        // ],
        qualityContPerMail: [
          { required: true, message: "请输入邮箱", trigger: "blur" },
          {
            type: "email",
            message: "请输入正确的邮箱地址",
            trigger: ["blur", "change"],
          },
        ],
        qualityContPerAddress: [
          { required: true, message: "请输入质控品投递地址", trigger: "blur" },
        ],
      },
      checkedPro: [],
      projectList: [],
    };
  },
  created() {
    // this.getProjectName()
    this.userForm.mobile = this.$route.query.phone;
    // console.log(this.userForm.mobile);
  },
  methods: {
    // 填完信息确认注册
    sub() {
      this.$refs.signRef.validate(async (val) => {
        if (!val) {
        } else {
          const { data: res } = await this.$http.post(
            "person/register",
            this.userForm
          );
          console.log(res);
          if (res.code !== 200) {
            this.$message.error(res.message);
          } else {
            this.$message.success("注册成功");
            // this.$router.push('/userhome')
            // this.getProjectName();
            // this.ProVisible = true;
            window.sessionStorage.setItem("userName", this.userForm.username);
            window.sessionStorage.setItem("phone", this.userForm.mobile);
            await this.login();
            this.$router.push("/userhome");
          }
        }
      });
    },
    // 获取项目名称
    async getProjectName() {
      const { data: res } = await this.$http.post("activity/apply/getYearInfo");
      this.projectList = res.data;
      console.log(res);
    },
    async login() {
      const { data: res } = await this.$http.post("person/login", {
        mobile: this.userForm.mobile,
        password: this.userForm.password1,
        username: this.userForm.username,
      });
      // console.log(res);
      if (res.code !== 200) {
        this.$message.error(res.message);
      } else {
        window.localStorage.setItem("token", res.data.accessToken);
      }
    },
    // 选择参加的项目
    async subPro() {
      if (this.checkedPro.length < 2) {
        this.$message.error("至少选择两个项目参加");
      } else {
        const { data: res } = await this.$http.post("activity/apply/newest", {
          userName: this.userForm.username,
          productList: this.checkedPro,
          mobile: this.userForm.mobile,
        });
        if (res.code !== 200) {
          this.$message.error(res.message);
        } else {
          this.$message.success("参加项目成功");
          window.sessionStorage.setItem("userName", this.userForm.username);
          window.sessionStorage.setItem("phone", this.userForm.mobile);
          await this.login();
          this.$router.push("/userhome");
        }
      }
    },
  },
};
</script>
<style lang='less' scoped>
#registe {
  min-height: 1000px;
}
.info_table {
  width: 44%;
  margin: 50px auto;
  table {
    text-align: center;
    td {
      padding: 10px 0;
    }
    input {
      margin: 0 10px;
      width: 505px;
      outline: none;
      border: none;
      font-size: 17px;
      text-align: center;
    }
  }
}
.el-form {
  width: 600px;
  margin: 60px auto 30px;
  .el-form-item {
    margin-bottom: 20px;
  }
}

.select {
  //background: red;
  width: 47%;
  margin: 0 auto;
  text-align: center;
  // display: flex;
  // justify-content: space-around;
  // flex-wrap: nowrap
  .el-button {
    display: block;
    margin: 50px auto;
  }
}
/deep/.el-input-group--append .el-input__inner {
  margin-left: 125px;
  width: 362px;
}
</style>