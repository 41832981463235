<template>
  <div id="app">
    <el-container class="home-container">
      <el-container>
        <!-- 侧边栏 -->
        <el-aside width="200px">
          <div class="logo">
            <img src="../../assets/logo.png" />
          </div>
          <!-- 侧边栏菜单 -->
          <el-menu
            background-color="#333744"
            text-color="#fff"
            active-text-color="#409eff"
            router
            :default-active="activePath"
          >
            <!-- 一级菜单 -->
            <el-submenu
              :index="item.id + ''"
              v-for="item in menuList"
              :key="item.id"
            >
              <!-- 一级菜单模板 -->
              <template slot="title">
                <!-- 图标 -->
                <i class="el-icon-menu"></i>
                <!-- 文本 -->
                <span>{{ item.authName }}</span>
              </template>
              <!-- 二级子菜单 -->
              <el-menu-item
                :index="'/' + subItem.path"
                v-for="subItem in item.children"
                :key="subItem.id"
                @click="
                  gorouter(item.authName, subItem.authName, '/' + subItem.path)
                "
              >
                <!-- 二级菜单模板 -->
                <template slot="title">
                  <!-- 文本 -->
                  <span>{{ subItem.authName }}</span>
                </template>
              </el-menu-item>
            </el-submenu>
          </el-menu>
          <el-button class="logout" type="info" @click="logout">退出</el-button>
        </el-aside>
        <!-- 主体结构 -->
        <el-main>
          <div class="top">
            <el-breadcrumb separator="/">
              <el-breadcrumb-item>{{ name }}</el-breadcrumb-item>
              <el-breadcrumb-item class="active" :to="{ path: '' }">{{
                itemName
              }}</el-breadcrumb-item>
            </el-breadcrumb>
            <div class="right">
              <!-- <i class="el-icon-question"></i>
              <span>帮助</span> -->
              <!-- <el-button type="info" @click="logout">注销</el-button> -->
              <span class="yuan"></span>
              <span>{{ username }}</span>
            </div>
          </div>
          <router-view></router-view>
        </el-main>
      </el-container>
    </el-container>
  </div>
</template>

<script>
export default {
  data() {
    return {
      username: "",
      // 左侧菜单数据
      menuList: [
        {
          authName: "质评公告与报名",
          id: 1,
          children: [
            { authName: "质评公告与报名", id: 1.1, path: "usernocite" },
          ],
        },
        {
          id: 2,
          authName: "EQA上报及成绩",
          children: [
            { authName: "数据上报列表", id: 2.1, path: "userreport" },
            { authName: "质评成绩列表", id: 2.2, path: "userresult" },
          ],
        },

        {
          authName: "系统管理",
          id: 3,
          children: [{ authName: "个人信息管理", id: 3.1, path: "userinfo" }],
        },
        {
          authName: "帮助中心",
          id: 4,
          children: [
            { authName: "操作说明", id: 4.1, path: "userexplain" },
            { authName: "联系管理员", id: 4.2, path: "usercontact" },
          ],
        },
      ],
      activePath: "/userreport",
      name: "室间质评",
      itemName: "数据上报列表",
    };
  },
  created() {
    // this.username = window.sessionStorage.getItem("userName");
    this.activePath = window.sessionStorage.getItem("activePath");
    this.name = window.sessionStorage.getItem("firstName");
    this.itemName = window.sessionStorage.getItem("subName");
    this.getUserInfo();
  },

  methods: {
    // 获取用户信息
    async getUserInfo() {
      const { data: res } = await this.$http.get("person/info");
      if (res.code != "000000") {
        this.$message.error(res.message);
      } else {
        this.username = res.data.username;
        // this.avatar = res.data.avatar;
        window.sessionStorage.setItem("phone", res.data.mobile);
        window.sessionStorage.setItem("userName", res.data.username);
      }
    },
    gorouter(firstName, subName, path) {
      // var firstName = this.menuList.filter((item) => {
      //   return item.id == id;
      // });
      // this.name = firstName[0].authName;
      // var subName = firstName[0].children.filter((item) => {
      //   return item.id == itemId;
      // });
      // this.itemName = subName[0].authName;

      window.sessionStorage.setItem("activePath", path);
      window.sessionStorage.setItem("firstName", firstName);
      window.sessionStorage.setItem("subName", subName);
      this.activePath = path;
      this.name = firstName;
      this.itemName = subName;
    },
    async logout() {
      await this.$http.post("person/logout");
      window.sessionStorage.clear();
      window.localStorage.clear();
      this.$router.push("userlogin");
    },
  },
};
</script>

<style lang="less" scoped>
#app {
  height: 100%;
}
.home-container {
  height: 100%;
}

.el-aside {
  background-color: #333744;
  height: 100%;
  .el-menu {
    border-right: none;
  }
}
.el-main {
  background-color: #eaedf1;
  height: 100%;
}
.logo {
  width: 145px;
  height: 32px;
  color: #fff;
  background: #fff;
  margin: 20px 25px;
  display: inline-block;
  text-align: center;
  border-radius: 5px;
  padding-top: 5px;
  img {
    width: 100%;
    height: 100%;
  }
}
.logout {
  width: 150px;
  margin: 20px 0 0 20px;
}
</style>
