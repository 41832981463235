<template>
  <div id="w">
    <!-- <div class="head">
      <el-carousel :interval="5000" arrow="always">
        <el-carousel-item>
          <img src="../../assets/01.png" />
        </el-carousel-item>
        <el-carousel-item>
          <img src="../../assets/02.png" />
        </el-carousel-item>
      </el-carousel>
    </div> -->
    <el-button type="primary" @click="goback">返回</el-button>
    <div class="content">
      <h3>{{ title }}</h3>
      <div class="details" v-html="content"></div>
    </div>
  </div>
</template>

<script>
let Base64 = require("js-base64").Base64;
export default {
  data() {
    return {
      id: "",
      title: "",
      content: "",
    };
  },
  created() {
    this.id = this.$route.query.id;
    this.getNewsDetails();
  },
  methods: {
    async getNewsDetails() {
      const { data: res } = await this.$http.post(
        "sys/news/info/newsInfoById",
        {
          id: this.id,
        }
      );
      if (res.code != 200) {
        this.$message.errror(res.message);
      } else {
        this.title = res.data.title;
        this.content = Base64.decode(res.data.content);
      }
      // var t = document.getElementsByClassName("details");
      //   // 重点：将字符串解析成html进行展示
      //   t.innerHTML = this.content;
      // console.log(t.innerHTML);
    },
    goback() {
      this.$router.go(-1);
    },
  },
};
</script>
<style lang='less' scoped>
#w {
  width: 60%;
  margin: 0 auto;
  .head {
    width: 100%;
  }
  .content {
    h3 {
      font-size: 25px;
      text-align: center;
    }
    p {
      // text-indent: 2em;
      font-size: 18px;
      line-height: 30px;
      white-space: pre-wrap;
    }
  }
}
</style>